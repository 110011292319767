export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.navItemDropdownMenu': {
      width: 'fit-content'
    },
    '.container': {
      position: 'absolute!important',
      padding: ['1rem', '', '', '1.5rem 0.5rem'],
      // position: 'static',
      background: 'linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 25%, rgba(0, 0, 0, 0) 100%)',
      color: 'black'
    },
    // '.menu': {
    //   display: 'none',
    // },
    '.containerScrolled': {
      position: 'absolute!important',
      backgroundColor: 'white',
      // color: 'black',
      padding: '0.25rem',
      borderBottom: 'solid 1px',
      borderColor: 'primary',

      '.smallNavMenu': {
        '.menu': {
          display: 'none'
        },
        '> div': {
          a: {
            fontSize: ['', '', '1rem', '1rem', '1.15rem'],
            color: 'dark',
            letterSpacing: '0px',
            ':hover': {
              color: 'primary'
            }
          }
        }
      }
    },

    '.hamburger': {
      //   display: ['', '', '', 'none'], temp remove hamburger now that we added 2 new nav items
      '> div': {
        backgroundColor: 'primary'
      }
    },

    '.smallNavMenu': {
      '> div': {
        // borderColor: 'none',
        // border: 'none',
        border: 'none',
        a: {
          fontSize: ['', '', '1rem', '12px'],
          color: 'white',
          letterSpacing: '0px',
          ':hover': {
            color: 'primary'
          }
        }
      }
    },

    '.logoLocationContainer': {},
    '.logo': {
      // filter: 'brightness(0) invert(1)',
      padding: '0rem 0.5rem',
      img: {
        maxHeight: '125px'
      }
    },
    '.logoScrolled': {
      // filter: 'brightness(0)',
      img: {
        maxHeight: '100px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '1rem 0rem',
      img: {}
    },

    '.navMenuOpen': {
      color: 'white',
      a: {
        color: 'white',
        transition: 'all .25s',
        '&:hover': {
          color: 'primary',
          transition: 'all .25s'
        }
      }
    },
    '.phoneContainer': {
      display: 'none'
    },
    '.hamburgerOpen': {
      '> div': {
        backgroundColor: 'primary'
      }
    },

    // '.facebook': {
    //   borderColor: '#1877f2',
    //   'svg path': {
    //     fill: '#1877f2 !important',
    //   },
    // },
    // '.instagram': {
    //   borderColor: 'white',
    //   'svg path': {
    //     fill: 'white !important',
    //   },
    // },
    '.socialIconsContainer': {
      flexGrow: '1',
      '.socialContainer': {
        img: {
          height: '48px',
          width: '48px'
        },
        svg: {
          backgroundColor: '#20bed6',
          height: '48px',
          width: '48px',
          padding: '0.5rem'
        },
        '.gonation': {
          svg: {
            backgroundColor: '#20bed6'
          }
        }
      }
    }
    // '.gonation': {
    //   borderColor: '#20bed6',

    //   'svg path': {
    //     fill: '#20bed6 !important',
    //   },
    // },
  },

  footer: {
    maxWidth: 'unset',
    backgroundColor: 'black',
    color: 'white',
    '.logo': {
      img: {
        maxHeight: '75px'
      }
    },
    // borderTop: 'solid 2px',
    // borderColor: 'primary',
    '.multiButtonContainer': {
      //   display: ['', '', 'flex'],
      //   flexDirection: 'row',
      a: {
        variant: 'buttons.secondary',
        color: 'white',
        '&:hover': {
          backgroundColor: 'primary',
          backgroundImage: 'none!important'
        }
      }
    },
    '.gonationLogo': {
      justifyContent: ['', '', '', 'flex-end'],
      svg: {
        fill: 'white'
      }
    },

    '.contactDetails-container': {
      //   display: 'none',
    },
    '.socialContainer': {
      //   a: { margin: '0.25rem' },
      //   img: {
      //     height: '48px',
      //     width: '48px',
      //   },
      //   svg: {
      //     backgroundColor: '#20bed6',
      //     height: '48px',
      //     width: '48px',
      //     padding: '0.5rem',
      //   },
      '.gonation': {
        // svg: {
        //   backgroundColor: '#20bed6',
        // },
      }
      // svg: {
      //   width: ['50px', '70px'],
      //   height: ['50px', '70px'],
      //   margin: '1rem',
      //   borderRadius: '1500px',
      //   padding: '0.5rem',
      //   // border: 'solid 2px',
      //   borderColor: 'primary',
      //   transition: 'all ease-in-out 0.8s',
      //   path: {
      //     fill: 'primary',
      //   },
      //   ':hover': {
      //     backgroundColor: '#202020',
      //     // borderColor: 'tertiary',
      //     path: {
      //       fill: 'light',
      //     },
      //   },
      // },

      // '.facebook': {
      //   borderColor: '#1877f2',
      //   'svg path': {
      //     fill: '#1877f2 !important',
      //   },
      // },
      // '.instagram': {
      //   borderColor: 'white',
      //   'svg path': {
      //     fill: 'white !important',
      //   },
      // },

      // '.gonation': {
      //   borderColor: '#20bed6',
      //   'svg path': {
      //     fill: '#20bed6 !important',
      //   },
      // },
    }
  },

  ctaWidget: {
    a: {
      fontSize: '1.1rem'
      //   fontWeight: 'bold',
    },
    '#online-order-cta': {
      span: {
        display: 'inline'
      },
      svg: {
        marginRight: '0.5rem'
      }
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: '700',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'primary',
    order: '1',
    textTransform: 'uppercase'
  },
  subtitle: {
    color: 'primary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2.2rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontWeight: '500',
    fontFamily: 'subheading'
  },
  text: {
    textAlign: 'left',
    lineHeight: '1.75',
    order: '3',
    p: {
      lineHeight: '1.75',
      textAlign: 'left',
      fontSize: 'inherit',
      fontWeight: '500'
    }
  },

  sideBySide1: {
    backgroundColor: 'white',
    // backgroundColor: 'transparent',
    // margin: ['1.5rem', '', '4rem 2rem', '10rem 3rem'],
    padding: ['1rem', '', '2rem', '3rem'],
    // backgroundColor: 'black',
    // borderRadius: '20px',
    // '.lazyload-wrapper': {},
    '.content': {
      // variant: 'customVariants.borderCorners',
      position: 'relative',
      alignItems: 'flex-start',
      backgroundColor: 'transparent',
      // margin: ['1rem 0rem', '', '0rem 1rem'],
      padding: ['2rem 1rem 2rem 3rem ', '2rem', '3rem', '4rem']
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      borderBottom: '2px solid',
      fontFamily: 'display'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
      p: {
        color: 'dark'
      },
      color: 'dark'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.lazyload-wrapper': {
      //   border: '6px solid',
      //   borderColor: 'tertiary',
    }
  },

  // sideBySide1: {
  //   // backgroundColor: 'transparent',
  //   margin: ['1.5rem', '', '4rem 2rem', '10rem 3rem'],
  //   backgroundColor: 'black',
  //   // borderRadius: '20px',
  //   '.lazyload-wrapper': {
  //     // maxHeight: '75vh',
  //     paddingBottom: ['', '', '500px'],
  //     marginRight: ['', '', '2rem'],
  //     position: 'relative',
  //     padding: ['2rem 3rem', '2rem 5rem', 'unset'],
  //     '.image': {
  //       position: ['', '', 'absolute'],
  //       left: '50%',
  //       top: '50%',
  //       transform: ['', '', 'translate(-50%, -50%)'],
  //       height: ['', '', '130%'],
  //       // borderRadius: '20px',
  //       // objectFit: 'contain',
  //     },
  //   },
  //   '.content': {
  //     // variant: 'customVariants.borderCorners',
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     backgroundColor: 'transparent',
  //     margin: ['1rem 0rem', '', '0rem 1rem'],
  //   },
  //   '.title': {
  //     variant: 'customVariants.title',
  //     color: 'white',
  //   },
  //   '.subtitle': { variant: 'customVariants.subtitle' },
  //   '.text': {
  //     variant: 'customVariants.text',
  //     p: {
  //       color: 'white',
  //     },
  //     color: 'white',
  //   },
  //   a: {
  //     order: '4',
  //   },
  //   '.ctaButton': {
  //     variant: 'buttons.primary',
  //   },
  // },

  // sideBySide2: {
  //   variant: 'customVariants.sideBySide1',
  //   '.imageContainer': {
  //     height: ['', '', '75vh'],
  //   },
  //   '.content': {
  //     // variant: 'customVariants.borderCorners',
  //     order: '2',
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     margin: ['1rem 0rem', '', '0rem 1rem'],
  //   },
  //   a: {
  //     order: '4',
  //   },
  // },

  contentBlock: {
    backgroundColor: 'transparent',
    minHeight: '75vh',
    padding: '2rem 2rem',
    '.section': {
      margin: 'auto auto',
      padding: '0.5rem',
      alignItems: 'center'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'dark',
      order: '2',
      fontFamily: 'display'
    },

    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      width: 'fit-content',
      justifyContent: 'center',
      order: '1',
      textShadow: '2px 2px 10px black',
      color: 'white',
      fontFamily: 'display',
      fontSize: ['', '', '', '4rem', '5rem']
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      order: '3',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      order: '5',

      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    height: ['67.5vh', '', '100vh'],
    marginBottom: ['190px', '', '0rem'],
    '.section': {
      margin: 'auto auto',
      padding: '0.5rem'
    },
    '.hero_content_container': {
      width: ['100%', '', '100%'],
      maxWidth: 'unset',
      alignItems: 'center',
      textAlign: 'center',
      margin: ' auto',
      display: 'none'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '3rem', '4rem', '5rem', '6rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'dark',
      padding: '1rem',
      fontFamily: 'display'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.5rem', '2rem', '2.5rem', '3rem', '3.5rem'],
      width: '100%',
      textAlign: 'center',
      color: 'primary',
      width: '100%',
      justifyContent: 'center'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-slider': {
      height: ['67.5vh', '', '100vh'],
      '.slick-slide > div': {
        height: ['67.5vh', '', '100vh']
      },
      '.slick-prev, .slick-next': {
        display: 'none !important'
      },
      '.slick-slide img': {
        height: ['67.5vh', '', '100vh'],
        filter: 'brightness(0.7)'
      },
      '.slick-dots': {
        right: '5rem',
        width: 'fit-content',
        display: 'none !important'
      }
    }
  },

  homepageHeroShout: {
    // display: 'none',
    padding: ['0.5rem', '', '0rem'],
    backgroundColor: ['black', '', 'none'],

    '.shoutWidget': {
      position: ['', '', 'absolute'],
      bottom: ['1rem', '', '2rem', '4rem'],
      left: ['1rem', '', '2rem', '4rem'],
      backgroundColor: ['black', '', 'rgba(0,0,0,0.7)'],
      margin: '0rem',
      border: 'none',
      borderLeft: '3px solid',
      borderColor: 'primary',
      maxWidth: ['unset', '', '500px'],
      padding: ['0.5rem', '', '0rem'],
      '.imageContainer': {
        width: ['30%', '', '40%'],
        order: ['2', '', '1'],
        img: {
          border: 'none'
        }
      },

      '.content': {
        order: ['1', '', '2']
      },
      '.title': {
        color: 'primary',
        padding: '0rem',
        margin: '0rem',
        borderBottom: '1px solid',
        width: 'fit-content'
      },

      '.text': {
        textAlign: 'left'
      },
      '.date': {
        order: '5',
        color: 'lightgrey'
      }
    }
  },

  locationBoxes1: {
    // ! in component styles also
    padding: '0.5rem'
  },

  homepageLocationsBar: {
    background: 'unset',
    backgroundColor: 'white',
    padding: ['1rem 1rem 0rem', '', '2rem 1rem 1rem'],
    color: 'black',
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '0rem',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '900',
      letterSpacing: '-1px',
      fontSize: ['2.25rem', '3rem', '3.5rem', '4rem', '4.5rem'],
      textAlign: 'center',
      ':after': {
        content: "''",
        borderRadius: '252px 55px 104px 20px / 34px',
        background:
          'linear-gradient(90deg, rgba(51,180,76,1) 0%, lightgrey 33%, lightgrey 66%, rgba(217,32,39,1) 100%);',
        height: '5px',
        left: '0px',
        width: '100%'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },
  homepageCatering: {
    variant: 'customVariants.contentBlock'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'

    // variant: 'customVariants.contentBlock',
  },

  homepageGallery: {
    variant: 'customVariants.contentBlock',
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset'],
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['3.5rem', '4.5rem', '5.5rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center',
      color: 'dark',
      order: '2',
      //   fontFamily: 'display',
      //   color: ''
      display: 'none'
    }
  },
  homepageEvents: {
    variant: 'customVariants.contentBlock',
    background: '#ffffffa6',
    padding: '3rem 2rem 5rem',
    margin: '0rem',
    color: 'black',
    textAlign: 'left',
    '.hero_content_container': {
      width: '100%',
      left: '0rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0rem auto',
      textAlign: 'center'
    },
    // backgroundImage:
    //   'url(https://res.cloudinary.com/gonation/image/upload/v1637186812/sites/westover-pizza/motorcycle.png)',
    // backgroundSize: '25%',
    // backgroundPosition: ['98% 40%', '', '5% 20%'],
    // backgroundRepeat: 'no-repeat',
    '.title': {
      variant: 'customVariants.title',
      width: '100%',
      textAlign: 'center',
      border: 'none',
      position: 'static',
      justifyContent: 'center',
      color: 'dark',
      fontFamily: 'display'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      color: 'tertiary',
      textShadow: 'none',
      textAlign: 'center'
    },
    '.text': {
      color: 'black',
      order: '3',
      p: {
        color: 'black'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    },
    '.slick-slider': {
      width: '100%',
      left: '0rem'
    }
  },

  homepageContact: {
    variant: 'customVariants.contentBlock',
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset']
  },

  homepageShout: {
    '.containerPopUp': {
      padding: '0rem',
      border: 'none',
      borderTop: 'solid 5px',
      borderColor: 'primary',
      backgroundColor: 'black',
      width: ['85%', '70%', '', '50%'],
      '::-webkit-scrollbar': {
        width: '0px'
      },
      '.shoutContentContainerPopUp': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: ['1rem']
      },
      '.title': {
        fontFamily: 'heading',
        fontSize: '1.2rem',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        borderBottom: '3px solid',
        width: 'fit-content'
      },
      '.date': {
        color: 'white',
        order: '4',
        opacity: '0.6',
        marginBottom: '0rem'
      },
      '.text': {
        color: 'white',
        fontFamily: 'sans-serif',
        fontSize: '1rem',
        fontWeight: 'bold',
        lineHeight: '1.75',
        textTransform: 'capitalize',
        marginBottom: '0.75rem'
      }
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    backgroundColor: 'rgba(255,255,255,0.4)',
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
      //   fontFamily: 'display',
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumPhotos': {
      gridGap: '0!important'
    },
    '.albumsContainer': {
      backgroundColor: 'primary',
      flexDirection: 'row-reverse'
    },
    '.albumTitle': {
      padding: '0.5rem',
      color: 'white',
      border: 'none'
    },
    '.albumName': {
      color: 'primary',
      opacity: '1'
    },
    '.albumImage': {
      padding: '.10rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      '.logoContainer': {
        // display: 'none',
      },
      h4: {
        display: 'none'
      },
      h5: {
        display: 'none'
      },
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      color: 'black',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    backgroundPosition: 'center center',
    color: 'black',
    backgroundColor: 'rgba(255,255,255,0.8)',
    backgroundColor: '#ffffff',
    backgroundImage: `url("https://www.transparenttextures.com/patterns/tex2res1.png")`,
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'black',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      backgroundColor: 'rgba(255,255,255,0.6)',
      color: 'black',
      '::placeholder': {
        color: 'black'
      }
    },

    '.submitBtn': {
      variant: 'buttons.primary',
      backgroundColor: 'primary',
      height: 'unset',
      width: 'auto'
    }
  },
  hours: {
    padding: ['3rem 1rem', '5rem 1rem'],
    backgroundColor: 'primary',
    color: 'white'
  },

  locationMap: {
    '.dayofWeekText': {
      color: 'primary',
      marginBottom: '4px',
      textTransform: 'uppercase'
    },
    '.label, .time': {
      fontWeight: 600
    },
    '.dayContainer': {
      border: '1px solid',
      borderColor: '#e1e1e1',
      padding: '8px',
      justifyContent: 'flex-start'
    }
  },
  //   order: '3',
  //   '.content_container': {
  //     padding: '0rem',
  //   },
  //   h3: {
  //     variant: 'customVariants.title',
  //     justifyContent: 'center',
  //     display: 'flex',
  //     order: 'unset',
  //     width: '100%',
  //     backgroundColor: 'secondary',
  //     padding: '0.5rem 0.5rem 1rem',
  //     textAlign: 'center',
  //     fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
  //     textAlign: 'center',
  //     alignItems: 'center',
  //   },
  //   '.contactDetails-container': {
  //     padding: '0rem 1rem 3rem',
  //   },
  //   '.hours-section': {
  //     maxWidth: 'unset',
  //     padding: '0rem',
  //     '.hoursContainer': {
  //       padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem'],
  //     },
  //   },
  '.dayofWeekText': {
    color: 'primary'
  },
  //   '.textContent': {
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     textAlign: 'center',
  //     display: 'flex',
  //   },
  // },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      flexGrow: '1'
    },
    '.textContent': {},
    '.imageContainer': {
      '.imageFill': {
        paddingBottom: '65%'
      },
      img: {
        objectFit: 'cover'
        // padding: '1rem',
      }
    }
  },

  partiesAndCatering: {
    backgroundColor: '#f7f7f7',
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: 'primary'
    },
    '.title': {
      fontFamily: 'display',
      textAlign: 'center!important'
    },
    '.title, .subtitle': {
      textAlign: 'center!important'
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },
  pageHero: {
    '.title': {
      fontFamily: 'display',
      textTransform: 'uppercase'
    }
  }
}
